import http from '../plugins/Axios/axios';
const memberInfo = JSON.parse(localStorage.getItem('memberInfo'));

export default {
  namespaced: true,
  state: {
    data: [],
    access_token: null,
    memberInfo: memberInfo,
  },
  getters: {
    memberInfo: (state) => state.memberInfo,
    userInfo: (state) => state.data,
  },
  mutations: {
    SET_USER(state, data) {
      state.data = data;
    },
    SET_DATA(state, data) {
      state.data = data;
    },
    refreshToken(state, access_token) {
      state.access_token = access_token;
    },
  },
  actions: {
    fetchData: async ({ commit }, payload) => {
      let res = await http.get(`/user/me`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_USER', result);
      return result;
    },
    signIn: async ({ commit }, payload) => {
      let res = await http.post(`/user/signin`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },
  },
};
