import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    product: [],
    products: [],
    data: [],
  },
  getters: {
    productById: (state) => state.product,
    getProducts: (state) => state.products,
    fetchProductInStock: (state) => state.data,
    productInfo: (state) => state.data,
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_PRODUCT_INSTOCK(state, data) {
      state.data = data;
    },
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_VARIANT(state, data) {
      state.data = data;
    },
  },
  actions: {
    create: async ({ commit }, payload = []) => {
      let res = await http.post(`/products`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    create_variants: async ({ commit }, payload = []) => {
      const { products_image } = payload;
      var formData = new FormData();
      formData.append('file', products_image);
      console.log('formData', formData);
      console.log('products_image', products_image);
      let res = await http.post(`/products/create_variants`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    Search: async ({ commit }, payload = []) => {
      let res = await http.get(`/products/search`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    async fetchData({ commit }, payload = []) {
      try {
        const shopId = payload.shop_id;
        let res = await http.get(`/products/${shopId}`, {
          params: payload,
        });
        let result = res.data != undefined ? res.data : [];
        commit('SET_PRODUCTS', result);
        return result;
      } catch (err) {
        console.log(err);
        commit('SET_PRODUCTS', []);
      }
    },
    fetchDatas: ({ commit }, payload = []) => {
      const shopId = payload.shop_id;
      http
        .get(`/products/${shopId}`, { params: payload })
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_PRODUCTS', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_PRODUCTS', []);
        });
    },
    getById: async ({ commit }, payload = []) => {
      const { shop_id: shopId, product_id: productId } = payload;
      let { data: res } = await http.get(`/products/${shopId}/${productId}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_PRODUCT', result);
      return result;
    },
    checkInStock: async ({ commit }, payload = []) => {
      const { shop_id: shopId } = payload;
      let res = await http.get(`/products_stock/check_in_stock/${shopId}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_PRODUCT_INSTOCK', result);
      return result;
    },
    createVariant: async ({ commit }, payload = []) => {
      let res = await http.post(`/products_variants`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_VARIANT', result);
      return result;
    },
  },
};
