import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    shippings: [],
  },
  getters: {
    getShippings: (state) => state.shippings,
  },
  mutations: {
    SET_SHIPPINGS(state, shippings) {
      state.shippings = shippings;
    },
  },
  actions: {
    fetchData: ({ commit }) => {
      http
        .get(`/shippings`)
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_SHIPPINGS', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_SHIPPINGS', []);
        });
    },
    fetchDatas: async ({ commit }, payload) => {
      let res = await http.get(`/shippings`);
      let result = res.data != undefined ? res.data : [];
      commit('SET_SHIPPINGS', result);
      return result;
    },
  },
};
