import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchData: async ({ commit }, payload) => {
      let res = await http.get(`/courier/Non-Serviceable`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
  },
};
