import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
    datas: [],
    info: [],
    movement: [],
  },
  getters: {
    stockInfo: (state) => state.data,
    warehouseInfo: (state) => state.datas,
    dataInfo: (state) => state.info,
    movementInfo: (state) => state.movement,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_INFO(state, data) {
      state.info = data;
    },
    SET_DATAS(state, data) {
      state.datas = data;
    },
    SET_MOVEMENT(state, data) {
      state.movement = data;
    },
  },
  actions: {
    fetchData: async ({ commit }, payload) => {
      let res = await http.get(`/warehouse`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATAS', result);
      return result;
    },
    getById: async ({ commit }, payload) => {
      const { shop_id, warehouse_id } = payload;
      let res = await http.get(`/warehouse/shop/${shop_id}/${warehouse_id}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_INFO', result);
      return result;
    },
    stock: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.get(`/warehouse/stock/${shop_id}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    stockMovement: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.get(`/stock_movements/${shop_id}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_MOVEMENT', result);
      return result;
    },
  },
};
