import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
    inventory: [],
  },
  getters: {
    fetchProductInStock: (state) => state.data,
    stockInfo: (state) => state.inventory,
  },
  mutations: {
    SET_PRODUCT_INSTOCK(state, data) {
      state.data = data;
    },
    SET_DATA(state, data) {
      state.inventory = data;
    },
  },
  actions: {
    checkInStock: async ({ commit }, payload = []) => {
      const { shop_id: shopId } = payload;
      let res = await http.get(`/products_stock/check_in_stock/${shopId}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_PRODUCT_INSTOCK', result);
      return result;
    },
    import: async ({ commit }, payload = []) => {
      let res = await http.post(`/products_stock/import`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
  },
};
