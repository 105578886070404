import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    salechannel: [],
    data: [],
  },
  getters: {
    getChannel: (state) => state.salechannel,
    channelInfo: (state) => state.data,
  },
  mutations: {
    SET_SALECHANNEL(state, salechannel) {
      state.salechannel = salechannel;
    },
    SET_DATA(state, data) {
      state.data = data;
    },
  },
  actions: {
    fetchData: ({ commit }, id) => {
      http
        .get(`/salechannel/${id}`)
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_SALECHANNEL', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_SALECHANNEL', []);
        });
    },
    fetchDataById: async ({ commit }, payload) => {
      const { shop_id, id } = payload;
      let res = await http.get(`/salechannel/${shop_id}/${id}`);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
  },
};
