<template>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info">
            <h3>0</h3>
            <span>ยอดขาย</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info">
            <h3>0</h3>
            <span>ออเดอร์</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info">
            <h3>0</h3>
            <span>ยอดเฉลี่ย/ออเดอร์</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info">
            <h3>0</h3>
            <span>เป้าหมาย</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
export default {
  data() {
    return {};
  },
  components: {},
};
</script>
