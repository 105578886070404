import http from '../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
    datas: [],
  },
  getters: {
    dataInfo: (state) => state.data,
    datasInfo: (state) => state.datas,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_DATAS(state, data) {
      state.datas = data;
    },
  },
  actions: {
    create: async ({ commit }, payload = []) => {
      let res = await http.post(`/products_attributes`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    fetchData: async ({ commit }, payload = []) => {
      const { shop_id: shopId, id } = payload;
      let res = await http.get(`/products_attributes/${shopId}/${id}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    fetchDatas: async ({ commit }, payload = []) => {
      const { shop_id: shopId } = payload;
      let res = await http.get(`/products_attributes/${shopId}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATAS', result);
      return result;
    },
  },
};
