import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
  },
  getters: {
    productReserve: (state) => state.data,
  },
  mutations: {
    SET_PRODUCT_RESERVE(state, data) {
      state.data = data;
    },
  },
  actions: {
    async create({ commit }, payload = []) {
      try {
        let { data: res } = await http.post(`/products_reserve`, payload);
        let result = res.data != undefined ? res.data : [];
        commit('SET_PRODUCT_RESERVE', result);
        return result;
      } catch (err) {
        console.log(err);
        commit('SET_PRODUCT_RESERVE', []);
      }
    },
  },
};
