import http from './../plugins/Axios/axios';
import qs from 'qs';
export default {
  namespaced: true,
  state: {
    data: [],
    image: [],
  },
  getters: {
    fileInfo: (state) => state.data,
    getImage: (state) => state.image,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_IMAGE(state, image) {
      state.image = image;
    },
  },
  actions: {
    Image: ({ commit }, payload) => {
      http
        .post(`/upload`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_IMAGE', result);
          console.log('result', result);
          return result;
        })
        .catch((err) => {
          console.log(err);
          commit('SET_IMAGE', []);
        });
    },
    File: async ({ commit }, payload) => {
      let res = await http.post(`/upload`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
  },
};
