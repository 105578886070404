import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    count: [],
    order: [],
    orders: [],
    jobs: [],
    ids: [],
    data: [],
    datas: [],
    cancel: [],
    order_update: [],
    orderById: [],
  },
  getters: {
    data: (state) => state.data,
    count: (state) => state.count,
    Order: (state) => state.order,
    List: (state) => state.orders,
    Jobs: (state) => state.jobs,
    OrderDataByIds: (state) => state.ids,
    OrderConfirmInfo: (state) => state.datas,
    cancelInfo: (state) => state.cancel,
    orderUpdateInfo: (state) => state.order_update,
    orderInfo: (state) => state.orderById,
  },
  mutations: {
    SET_ORDER(state, orders) {
      state.orders = orders;
    },
    SET_ORDER_COUNT(state, count) {
      state.count = count;
    },
    SET_ORDER_ID(state, data) {
      state.data = data;
    },
    SET_JOBID(state, jobs) {
      state.jobs = jobs;
    },
    SET_ORDER_IDS(state, ids) {
      state.ids = ids;
    },
    SET_ORDERS(state, data) {
      state.datas = data;
    },
    SET_ORDER_CANCEL(state, data) {
      state.cancel = data;
    },
    SET_ORDER_UPDATE(state, data) {
      state.order_update = data;
    },
    SET_ORDER_BYID(state, data) {
      state.orderById = data;
    },
  },
  actions: {
    create: async ({ commit }, payload) => {
      let res = await http.post(`/order`, payload);
      let result = res.data != undefined ? res.data : [];
      return result;
    },
    update: async ({ commit }, payload) => {
      let res = await http.put(`/order`, payload);
      let result = res.data != undefined ? res.data : [];
      return result;
    },
    updateStatus: async ({ commit }, payload) => {
      let res = await http.put(`/order/update_status`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDER_UPDATE', result);
      return result;
    },
    fetchData: async ({ commit }, payload) => {
      let res = await http.get(`/order`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDER', result);
      return result;
    },
    fetchDatas: async ({ commit }, payload) => {
      // const access_token = $cookies.get('access_token');
      // console.log('access_token', access_token);
      let res = await http.get(`/order`, { params: payload });
      let result = res != undefined ? res.data : [];
      commit('SET_ORDER', result);
      return result;
    },
    fetchDataById: async ({ commit }, payload) => {
      const { shop_id, order_id } = payload;
      let res = await http.get(`/order/${shop_id}/${order_id}`);
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDER_ID', result);
      return result;
    },
    splitAddress: async ({ commit }, payload) => {
      let res = await http.post(`/order/split_address`, payload);
      let result = res.data != undefined ? res.data : [];
      return result;
    },
    jobId: async ({ commit }, payload) => {
      commit('SET_JOBID', payload);
      return payload;
    },
    fetchDataByIds: async ({ commit }, payload) => {
      let res = await http.post(`/order/ids`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDER_IDS', result);
      return result;
    },
    confirmAndInventoryUpdate: async ({ commit }, payload) => {
      let res = await http.post(`/order/confirm`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDERS', result);
      return result;
    },
    countOrder: async ({ commit }, payload) => {
      let res = await http.get(`/order/count`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDER_COUNT', result);
      return result;
    },
    cancel: async ({ commit }, payload) => {
      let res = await http.post(`/order/cancel`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_ORDER_CANCEL', result);
      return result;
    },
    getById: async ({ commit }, payload) => {
      let res = await http.get(`/order/detail`, {
        params: payload,
      });
      let result = res != undefined ? res.data : [];
      console.log('result', result);
      commit('SET_ORDER_BYID', result);
      return result;
    },
  },
};
