<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">สรุปยอดขาย</h3>
              <div class="row">
                <div class="col-xs-12 col-md-4">
                  <div class="form-group form-focus select-focus">
                    <label class="focus-label"
                      >ช่องทางการขาย <span class="text-danger">*</span></label
                    >
                    <vue-select
                      :options="salechannelInfo"
                      v-model="sale_channel_id"
                      @select="fetchData()"
                      :placeholder="`ช่องทางการขายทั้งหมด`"
                      :settings="{
                        templateResult: formatState,
                        templateSelection: formatState,
                      }"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-4">
                  <button
                    type="button"
                    :class="{ active: timeOption == 'isToday' }"
                    @click="filtersBy('isToday')"
                    class="btn btn-outline-warning m-1"
                  >
                    วันนี้</button
                  ><button
                    type="button"
                    :class="{ active: timeOption == 'isWeek' }"
                    @click="filtersBy('isWeek')"
                    class="btn btn-outline-warning m-1"
                  >
                    สัปดาห์นี้</button
                  ><button
                    type="button"
                    :class="{ active: timeOption == 'isMonth' }"
                    @click="filtersBy('isMonth')"
                    class="btn btn-outline-warning m-1"
                  >
                    เดือนนี้</button
                  ><button
                    type="button"
                    :class="{ active: timeOption == 'isFilter' }"
                    @click="filtersBy('isFilter')"
                    class="btn btn-outline-warning m-1"
                  >
                    เลือกวัน
                  </button>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group form-focus select-focus">
                    <vue-datepicker
                      locale="th"
                      range
                      multiCalendars
                      v-model="date_from"
                      :clearable="false"
                      @blur="filtersBy('isFilter')"
                      inputClassName="form-control"
                      hideInputIcon
                      autoApply
                    />
                    <label class="focus-label"
                      >เลือกวันที่เริ่มต้น - สิ้นสุด</label
                    >
                  </div>
                </div>
              </div>
              <vue3-chart-js
                v-if="!loading"
                :id="lineChart.id"
                :type="lineChart.type"
                :data="lineChart.data"
                :options="lineChart.options"
                :height="120"
              ></vue3-chart-js>

              <div class="p-5 text-center" v-if="loading">
                <img
                  src="../../../../assets/img/icon/analysis.svg"
                  width="150"
                />
                <h4>กำลังโหลดข้อมูล...</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="las la-wallet"></i></span>
          <div class="dash-widget-info">
            <h3>{{ numeral(statistics.total_sales).format('0,0') }}</h3>
            <span>ยอดขาย</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"
            ><i class="las la-file-invoice"></i
          ></span>
          <div class="dash-widget-info">
            <h3>{{ numeral(statistics.total_order).format('0,0') }}</h3>
            <span>ออเดอร์</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"
            ><i class="las la-chart-area"></i
          ></span>
          <div class="dash-widget-info">
            <h3>{{ numeral(statistics.average_per_order).format('0,0') }}</h3>
            <span>ยอดเฉลี่ย/ออเดอร์</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"
            ><i class="las la-balance-scale-left"></i
          ></span>
          <div class="dash-widget-info">
            <h3>0</h3>
            <span>อัตราการปิด</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card-group m-b-30">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">เป้าหมาย</span>
              </div>
              <div>
                <span class="text-danger">{{
                  numeral(statistics.goal_per_month).format('0,0')
                }}</span>
              </div>
            </div>
            <h3 class="mb-3">
              {{ numeral(statistics.sales_per_month).format('0,0') }}
            </h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                :style="`width: ${statistics.goal_percentage_per_month}%`"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">เป้าหมายรายเดือน</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">เป้าหมาย</span>
              </div>
              <div>
                <span class="text-danger">{{
                  numeral(statistics.goal_per_year).format('0,0')
                }}</span>
              </div>
            </div>
            <h3 class="mb-3">
              {{ numeral(statistics.sales_per_year).format('0,0') }}
            </h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                :style="`width: ${statistics.goal_percentage_per_year}%`"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">เป้าหมายรายปี</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">ออเดอร์ต่อวัน</span>
              </div>
              <div>
                <span class="text-danger">{{
                  numeral(statistics.goal_order_per_day).format('0,0')
                }}</span>
              </div>
            </div>
            <h3 class="mb-3">
              {{ numeral(statistics.get_order_per_day).format('0,0') }}
            </h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                :style="`width: ${statistics.order_percentage_per_day}%`"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">จำนวนออเดอร์ต่อวัน</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">ออเดอร์ต่อเดือน</span>
              </div>
              <div>
                <span class="text-danger">{{
                  numeral(statistics.goal_order_per_month).format('0,0')
                }}</span>
              </div>
            </div>
            <h3 class="mb-3">
              {{ numeral(statistics.get_order_per_month).format('0,0') }}
            </h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                :style="`width: ${statistics.order_percentage_per_month}%`"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">จำนวนออเดอร์ต่อเดือน</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {
  reactive,
  defineComponent,
  onBeforeMount,
  onMounted,
  computed,
  ref,
  defineAsyncComponent,
  inject,
} from 'vue';
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import numeral from 'numeral';
export default defineComponent({
  components: {
    Vue3ChartJs,
  },
  setup() {
    const memberInfo = inject('memberInfo') != null ? inject('memberInfo') : {};
    const date_from = ref([]);
    const labels = ref([]);
    const datasets = ref([]);
    const lineChart = ref({});
    const loading = ref(false);
    const sale_channel_id = ref(0);
    const shop_id = ref(null);
    const user_id = ref(null);
    const timeOption = ref('isToday');
    const dateFrom = ref(null);
    const dateTo = ref(null);
    const statistics = ref({});
    const store = useStore();
    onBeforeMount(() => {}),
      onMounted(() => {
        const { shopId, userId } = memberInfo;
        shop_id.value = shopId;
        user_id.value = userId;
        fetchData();
        fetchStatistics();
        setSalechannelInfo(shop_id.value);
      });
    const setSalechannelInfo = (shopId) => {
      store.dispatch(`salechannel/fetchData`, shopId);
    };
    const salechannelInfo = computed(() => {
      let { data: channels } = store.getters[`salechannel/getChannel`];
      let salechannelObj = channels != undefined ? channels : [];
      const salechannel = salechannelObj.map((e) => {
        return {
          text: e.sale_channel_name,
          id: e.sale_channel_id,
          image: e.sale_channel_image,
        };
      });
      return salechannel;
    });
    const formatState = (opt) => {
      if (!opt.id) {
        return opt.text.toUpperCase();
      }
      let optimage = opt.image;
      if (!optimage) {
        return opt.text.toUpperCase();
      } else {
        let $opt = $(
          '<span><img src="' +
            optimage +
            '" width="20px" /> ' +
            opt.text.toUpperCase() +
            '</span>'
        );
        return $opt;
      }
    };
    const filtersBy = async (type) => {
      timeOption.value = type;

      if (type == 'isFilter') {
        dateFrom.value = dayjs(date_from.value[0]).format('YYYY-MM-DD');
        dateTo.value = dayjs(date_from.value[1]).format('YYYY-MM-DD');
        if (dateFrom.value == null || dateTo.value == null) {
          return false;
        }
        timeOption.value = 'isFilter';
      }
      await fetchData();
      await fetchStatistics();
    };
    const fetchStatistics = async () => {
      const payload = {
        shop_id: shop_id.value,
        sale_channel: sale_channel_id.value,
        timeOption: timeOption.value,
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      };
      await store.dispatch(`report/fetchStatistics`, payload);
      let { data } = await store.getters['report/statisticsInfo'];
      if (data) {
        statistics.value = data;
      }
    };
    const fetchData = async () => {
      loading.value = true;
      const payload = {
        shop_id: shop_id.value,
        sale_channel: sale_channel_id.value,
        timeOption: timeOption.value,
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
      };
      await store.dispatch(`report/fetchData`, payload);
      let { data: resp } = await store.getters['report/chartInfo'];
      if (resp) {
        let labels = Object.keys(resp);
        let total_order = [];
        let total_price = [];
        for (const [key, value] of Object.entries(resp)) {
          total_order.push(value.total_order);
          total_price.push(value.total_price);
        }
        lineChart.value = {
          id: 'line',
          type: 'line',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'จำนวนสั่งซื้อ',
                data: total_order,
                fill: false,
                borderColor: '#ff9b44',
                backgroundColor: '#ff9b44',
              },
              {
                label: 'ยอดขาย',
                data: total_price,
                fill: false,
                borderColor: '#fc6075',
                tension: 0.5,
                backgroundColor: '#fc6075',
              },
            ],
          },
          options: {
            plugins: {},
          },
        };
      }
      loading.value = false;
    };
    const barChart = {
      type: 'bar',
      options: {
        min: 0,
        max: 100,
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
        },
      },
      data: {
        labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
        datasets: [
          {
            label: 'Total Income',
            backgroundColor: [
              '#ff9b44',
              '#ff9b44',
              '#ff9b44',
              '#ff9b44',
              '#ff9b44',
              '#ff9b44',
              '#ff9b44',
              '#ff9b44',
            ],
            data: [100, 75, 50, 75, 50, 75, 100],
          },
          {
            label: 'Total outcome',
            backgroundColor: [
              '#fc6075',
              '#fc6075',
              '#fc6075',
              '#fc6075',
              '#fc6075',
              '#fc6075',
              '#fc6075',
              '#fc6075',
            ],
            data: [90, 65, 40, 65, 40, 65, 90],
          },
        ],
      },
    };
    return {
      barChart,
      lineChart,
      datasets,
      labels,
      loading,
      sale_channel_id,
      formatState,
      salechannelInfo,
      date_from,
      filtersBy,
      timeOption,
      fetchData,
      numeral,
      statistics,
    };
  },
});
</script>
