<template>
  <div>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner" id="style-15">
        <perfect-scrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHanle"
        >
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li class="menu-title">
                <span>Main</span>
              </li>
              <li>
                <router-link to="/index"
                  ><i class="las la-house-damage"></i>
                  <span>หน้าหลัก</span></router-link
                >
              </li>
              <li class="submenu">
                <a
                  href="#"
                  v-bind:class="{
                    active:
                      currentPath == 'index' || currentPath == 'order-add',
                  }"
                  ><i class="las la-file-invoice"></i>
                  <span> คำสั่งซื้อ</span>
                  <span class="menu-arrow"></span
                ></a>
                <ul style="display: none">
                  <li>
                    <router-link to="/orders">รายการคำสั่งซื้อ</router-link>
                  </li>
                  <li>
                    <router-link to="/order-return">การคืนสินค้า</router-link>
                  </li>
                </ul>
              </li>
              <li class="submenu">
                <a href="#"
                  ><i class="las la-truck"></i> <span> การจัดส่ง</span>
                  <span class="menu-arrow"></span
                ></a>
                <ul style="display: none">
                  <li>
                    <router-link to="/ship-order">จัดส่งในจำนวนมาก</router-link>
                  </li>
                  <li>
                    <router-link to="/ship-setting"
                      >ตัวเลือกการจัดส่ง</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="menu-title">
                <span>Product</span>
              </li>
              <li class="submenu">
                <a
                  href="#"
                  v-bind:class="{
                    active:
                      currentPath == 'product-create' ||
                      currentPath == 'product-index',
                  }"
                  ><i class="las la-shopping-bag"></i> <span> สินค้า</span>
                  <span class="menu-arrow"></span
                ></a>
                <ul style="display: none">
                  <li>
                    <router-link
                      v-bind:class="{ active: currentPath == 'product-index' }"
                      to="/products"
                      >จัดการสินค้า</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      v-bind:class="{
                        active:
                          currentPath == 'variants-index' ||
                          currentPath == 'variants-create',
                      }"
                      to="/products/variants"
                      >จัดการตัวแปรสินค้า</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/products/create"
                      v-bind:class="{ active: currentPath == 'product-create' }"
                      >เพิ่มสินค้า</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="menu-title">
                <span>Inventory</span>
              </li>
              <li class="submenu">
                <a
                  v-bind:class="{
                    active:
                      currentPath == 'warehouse-list' ||
                      currentPath == 'warehouse-view',
                  }"
                  href="#"
                  ><i class="las la-warehouse"></i> <span> คลังสินค้า</span>
                  <span class="menu-arrow"></span
                ></a>
                <ul style="display: none">
                  <li>
                    <router-link
                      v-bind:class="{
                        active:
                          currentPath == 'warehouse-list' ||
                          currentPath == 'warehouse-view',
                      }"
                      to="/warehouse"
                      >จัดการคลังสินค้า</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/warehouse/create"
                      >เพิ่มคลังสินค้า</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="submenu">
                <a
                  href="#"
                  v-bind:class="{
                    active:
                      currentPath == 'inventory-index' ||
                      currentPath == 'inventory-stockin' ||
                      currentPath == 'inventory-stockout' ||
                      currentPath == 'inventory-manualstock' ||
                      currentPath == 'inventory-movement',
                  }"
                  ><i class="las la-box"></i> <span> สต๊อกสินค้า</span>
                  <span class="menu-arrow"></span
                ></a>
                <ul style="display: none">
                  <li>
                    <router-link
                      to="/inventory"
                      v-bind:class="{
                        active: currentPath == 'inventory-index',
                      }"
                      >จัดการสต๊อก</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/inventory/stock-in"
                      v-bind:class="{
                        active: currentPath == 'inventory-stockin',
                      }"
                      >สินค้าเข้า</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/inventory/stock-out"
                      v-bind:class="{
                        active: currentPath == 'inventory-stockout',
                      }"
                      >สินค้าออก</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      to="/inventory/movement"
                      v-bind:class="{
                        active: currentPath == 'inventory-movement',
                      }"
                      >ความเคลื่อนไหว</router-link
                    >
                  </li>
                </ul>
              </li>
              <li>
                <router-link
                  to="/purchase-order"
                  v-bind:class="{ active: currentPath == 'clients-list' }"
                  ><i class="las la-file-invoice-dollar"></i>
                  <span>ใบสั่งซื้อสินค้า</span></router-link
                >
              </li>
              <li>
                <router-link
                  to="/goods-receipt"
                  v-bind:class="{
                    active:
                      currentPath == 'goods-receipt' ||
                      currentPath == 'goods-receipt-create',
                  }"
                  ><i class="las la-file"></i>
                  <span>ใบรับสินค้า</span></router-link
                >
              </li>

              <li class="menu-title">
                <span>Chat</span>
              </li>
              <li>
                <router-link class="noti-dot" to="messenger"
                  ><i class="lab la-facebook-messenger"></i>
                  <span>รวมแชท</span></router-link
                >
              </li>
              <!--<li>
                <router-link to="/termination"
                  ><i class="las la-bullhorn"></i>
                  <span>บรอดแคสต์</span></router-link
                >
              </li>-->
              <li class="menu-title">
                <span>Report</span>
              </li>
              <li>
                <router-link to="/termination"
                  ><i class="las la-chart-bar"></i>
                  <span>ภาพรวมข้อมูล</span></router-link
                >
              </li>
              <li class="menu-title">
                <span>Settings</span>
              </li>
              <li>
                <router-link to="/setting/users"
                  ><i class="la la-user-plus"></i>
                  <span>ผู้ใช้งาน</span></router-link
                >
              </li>
              <li>
                <router-link to="/setting/goal"
                  ><i class="la la-crosshairs"></i>
                  <span>เป้าหมาย</span></router-link
                >
              </li>
              <li class="submenu">
                <a href="#"
                  ><i class="las la-credit-card"></i>
                  <span> การชำระเงิน </span> <span class="menu-arrow"></span
                ></a>
                <ul style="display: none">
                  <li>
                    <router-link to="/setting/payment-method">
                      ตัวเลือกการชำระเงิน
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/setting/payment-account">
                      บัญชีธนาคาร
                    </router-link>
                  </li>
                </ul>
              </li>
              <li>
                <router-link to="/setting/account"
                  ><i class="la la-cog"></i>
                  <span>ตั้งค่าบัญชี</span></router-link
                >
              </li>
              <li class="menu-title">
                <span>Manual</span>
              </li>
              <li>
                <a href="#"
                  ><i class="la la-file-text"></i>
                  <span>คู่มือการใช้งาน</span></a
                >
              </li>
            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- </sidebar> -->
  </div>
</template>
<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
export default {
  components: {
    PerfectScrollbar,
  },
  mounted() {
    $('#sidebar-menu a').on('click', function (e) {
      if ($(this).parent().hasClass('submenu')) {
        e.preventDefault();
      }
      if (!$(this).hasClass('subdrop')) {
        $('ul', $(this).parents('ul:first')).slideUp(350);
        $('a', $(this).parents('ul:first')).removeClass('subdrop');
        $(this).next('ul').slideDown(350);
        $(this).addClass('subdrop');
      } else if ($(this).hasClass('subdrop')) {
        $(this).removeClass('subdrop');
        $(this).next('ul').slideUp(350);
      }
    });
    $('#sidebar-menu ul li.submenu a.active')
      .parents('li:last')
      .children('a:first')
      .addClass('active')
      .trigger('click');
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      activeClass: 'active',
    };
    //  isactive : true
  },
  methods: {
    scrollHanle(evt) {},
  },
};
</script>
<style>
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}
.sidebar-menu li a.active {
  color: #333;
  background-color: #ffebda;
}
</style>
