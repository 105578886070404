import axios from './axios';
import { router } from '../../router/index';
import TokenService from './token.service';
const setup = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      // console.log(error);
      return error;
    }
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        // TokenService.removeUser();
        const access_token = TokenService.getLocalAccessToken();
        const refresh_token = TokenService.getLocalRefreshToken();
        const originalConfig = error.config;
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const resp = await axios.put(
              '/user/refreshtoken',
              {
                refresh_token: refresh_token,
              },
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            );
            const { token } = resp.data;
            // store.dispatch('users/refreshToken', accessToken);
            TokenService.updateLocalAccessToken(token);
            return axios(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
        if (
          error.response.status === 500 &&
          error.response.data.status_code === 401
        ) {
          TokenService.removeUser();
          await router.push('/login');
        }
      }
      return Promise.reject(error);
      // let isNetworkError = error?.message ?? null;
      // if (isNetworkError === 'Network Error') {
      //   alert('Network Error');
      //   return;
      // }
      // throw error;
    }
  );

  return axios;
};

export default setup;
