<template>
  <li class="nav-item dropdown has-arrow main-drop">
    <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
      <span class="user-img me-1"
        ><img src="../assets/img/profiles/i3ank.jpg" alt="" />
        <span class="status online"></span
      ></span>
      <span class="adminclass">i3ank</span>
    </a>
    <div class="dropdown-menu">
      <router-link class="dropdown-item" to="/profile">My Profile</router-link>
      <router-link class="dropdown-item" to="/settings">Settings</router-link>
      <router-link class="dropdown-item" to="/login">Logout</router-link>
    </div>
  </li>
</template>
