<template>
  <!-- Notifications -->
  <li class="nav-item dropdown">
    <a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
      <i class="fa fa-bell-o"></i> <span class="badge rounded-pill">0</span>
    </a>
    <div class="dropdown-menu notifications">
      <div class="topnav-dropdown-header">
        <span class="notification-title">Notifications</span>
        <a href="javascript:void(0)" class="clear-noti"> Clear All </a>
      </div>
      <div class="noti-content">
        <ul class="notification-list">
          <!-- <li class="notification-message">
            <router-link to="/activities">
              <div class="media d-flex">
                <span class="avatar flex-shrink-0">
                  <img alt="" src="../assets/img/profiles/avatar-02.jpg" />
                </span>
                <div class="media-body flex-grow-1">
                  <p class="noti-details">
                    <span class="noti-title">John Doe</span> added new task
                    <span class="noti-title">Patient appointment booking</span>
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">4 mins ago</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li class="notification-message">
            <router-link to="/activities">
              <div class="media d-flex">
                <span class="avatar flex-shrink-0">
                  <img alt="" src="../assets/img/profiles/avatar-03.jpg" />
                </span>
                <div class="media-body flex-grow-1">
                  <p class="noti-details">
                    <span class="noti-title">Tarah Shropshire</span> changed the
                    task name
                    <span class="noti-title"
                      >Appointment booking with payment gateway</span
                    >
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">6 mins ago</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li class="notification-message">
            <router-link to="/activities">
              <div class="media d-flex">
                <span class="avatar flex-shrink-0">
                  <img alt="" src="../assets/img/profiles/avatar-06.jpg" />
                </span>
                <div class="media-body flex-grow-1">
                  <p class="noti-details">
                    <span class="noti-title">Misty Tison</span> added
                    <span class="noti-title">Domenic Houston</span> and
                    <span class="noti-title">Claire Mapes</span> to project
                    <span class="noti-title">Doctor available module</span>
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">8 mins ago</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li class="notification-message">
            <router-link to="/activities">
              <div class="media d-flex">
                <span class="avatar flex-shrink-0">
                  <img alt="" src="../assets/img/profiles/avatar-17.jpg" />
                </span>
                <div class="media-body flex-grow-1">
                  <p class="noti-details">
                    <span class="noti-title">Rolland Webber</span> completed
                    task
                    <span class="noti-title"
                      >Patient and Doctor video conferencing</span
                    >
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">12 mins ago</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li class="notification-message">
            <router-link to="/activities">
              <div class="media d-flex">
                <span class="avatar flex-shrink-0">
                  <img alt="" src="../assets/img/profiles/avatar-13.jpg" />
                </span>
                <div class="media-body flex-grow-1">
                  <p class="noti-details">
                    <span class="noti-title">Bernardo Galaviz</span> added new
                    task <span class="noti-title">Private chat module</span>
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">2 days ago</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li> -->
        </ul>
      </div>
      <div class="topnav-dropdown-footer">
        <router-link to="/activities">View all Notifications</router-link>
      </div>
    </div>
  </li>
  <!-- /Notifications -->
</template>
