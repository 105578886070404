import { createRouter, createWebHistory } from 'vue-router';
import auth from './../middleware/auth';
const routes = [
  {
    path: '/index',
    name: 'indexs',
    component: () => import('../views/pages/dashboard/admin/main'),
    meta: {
      middleware: auth,
    },
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'PathNotFound',
  //   component: () => import('../views/pages/PathNotFound'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/employee-dashboard',
  //   name: 'employee-dashboard',
  //   component: () => import('../views/pages/dashboard/employee'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/activities',
  //   name: 'activities',
  //   component: () => import('../views/pages/activities'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/applied-jobs',
  //   name: 'applied-jobs',
  //   component: () => import('../views/pages/jobs/appliedjobs'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/approval-setting',
  //   name: 'approval-setting',
  //   component: () => import('../views/pages/settings/approvalsetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/apptitude-result',
  //   name: 'apptitute-result',
  //   component: () => import('../views/pages/jobs/aptituteresult'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/archived-jobs',
  //   name: 'archived-jobs',
  //   component: () => import('../views/pages/jobs/archivedjobs'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/assets',
  //   name: 'assets',
  //   component: () => import('../views/pages/assets'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/attendance',
  //   name: 'attendance',
  //   component: () => import('../views/pages/employees/attendance'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/attendance-employee',
  //   name: 'attendance-employee',
  //   component: () => import('../views/pages/employees/attendanceemployee'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/attendance-reports',
  //   name: 'attendance-reports',
  //   component: () => import('../views/pages/reports/attendancereport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/blank-page',
  //   name: 'blank-page',
  //   component: () => import('../views/pages/account/blankpage'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/budget-expenses',
  //   name: 'budget-expenses',
  //   component: () => import('../views/pages/accounting/budgetexpenses'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/budget-revenues',
  //   name: 'budget-revenues',
  //   component: () => import('../views/pages/accounting/budgetrevenues'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/budgets',
  //   name: 'budgets',
  //   component: () => import('../views/pages/accounting/budgets'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/candidates',
  //   name: 'candidates',
  //   component: () => import('../views/pages/jobs/candidatelist'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/categories',
  //   name: 'categories',
  //   component: () => import('../views/pages/accounting/categories'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/change-password',
  //   name: 'change-password',
  //   component: () => import('../views/pages/settings/changepassword'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/chat',
  //   name: 'chat',
  //   component: () => import('../views/pages/app/chat'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/client-profile',
  //   name: 'client-profile',
  //   component: () => import('../views/pages/profile/clientprofile'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/clients',
  //   name: 'clients',
  //   component: () => import('../views/pages/clients/index'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/clients-list',
  //   name: 'clients-list',
  //   component: () => import('../views/pages/clients/clientlistindex'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/components',
  //   name: 'components',
  //   component: () => import('../views/pages/components'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/compose',
  //   name: 'compose',
  //   component: () => import('../views/pages/app/email/compose'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/contacts',
  //   name: 'contacts',
  //   component: () => import('../views/pages/app/contacts'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/create-estimate',
  //   name: 'create-estimate',
  //   component: () => import('../views/pages/sales/estimates/createestimate'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/create-invoice',
  //   name: 'create-invoice',
  //   component: () => import('../views/pages/sales/invoices/createinvoice'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/cron-setting',
  //   name: 'cron-setting',
  //   component: () => import('../views/pages/settings/cronsetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/daily-reports',
  //   name: 'daily-reports',
  //   component: () => import('../views/pages/reports/dailyreports'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/data-tables',
  //   name: 'data-tables',
  //   component: () => import('../views/pages/table/datatable'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/departments',
  //   name: 'departments',
  //   component: () => import('../views/pages/employees/departments'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/designations',
  //   name: 'designations',
  //   component: () => import('../views/pages/employees/designations'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/edit-estimate',
  //   name: 'edit-estimate',
  //   component: () => import('../views/pages/sales/estimates/editestimate'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/edit-invoice',
  //   name: 'edit-invoice',
  //   component: () => import('../views/pages/sales/invoices/editinvoice'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/email-settings',
  //   name: 'email-settings',
  //   component: () => import('../views/pages/settings/emailsettings'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/employee-reports',
  //   name: 'employee-reports',
  //   component: () => import('../views/pages/reports/employeereports'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/employees',
  //   name: 'employees',
  //   component: () => import('../views/pages/employees/mainemployee'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/employees-list',
  //   name: 'employees-list',
  //   component: () =>
  //     import('../views/pages/employees/mainemployee/employeeslistindex'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/error-404',
  //   name: 'error-404',
  //   component: () => import('../views/pages/error/error404'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/error-500',
  //   name: 'error-500',
  //   component: () => import('../views/pages/error/error500'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/estimates',
  //   name: 'estimates',
  //   component: () => import('../views/pages/sales/estimates/mainestimate'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/estimate-view',
  //   name: 'estimate-view',
  //   component: () => import('../views/pages/sales/estimates/estimateview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/events',
  //   name: 'events',
  //   component: () => import('../views/pages/app/events'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/expense-reports',
  //   name: 'expense-reports',
  //   component: () => import('../views/pages/reports/expensereports'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/expenses',
  //   name: 'expenses',
  //   component: () => import('../views/pages/sales/expenses'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/experiance-level',
  //   name: 'experiance-level',
  //   component: () => import('../views/pages/jobs/experiancelevel'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   component: () => import('../views/pages/mainpage/faq'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/file-manager',
  //   name: 'file-manager',
  //   component: () => import('../views/pages/app/filemanager'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'forgot-password',
  //   component: () => import('../views/pages/mainpage/forgotpassword'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/form-basic-inputs',
  //   name: 'form-basic-inputs',
  //   component: () => import('../views/pages/form/basicinputs'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/form-horizontal',
  //   name: 'form-horizontal',
  //   component: () => import('../views/pages/form/horizontal'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/form-input-groups',
  //   name: 'form-input-groups',
  //   component: () => import('../views/pages/form/inputgroups'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/form-mask',
  //   name: 'form-mask',
  //   component: () => import('../views/pages/form/formmask'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/form-validation',
  //   name: 'form-validation',
  //   component: () => import('../views/pages/form/formvalidation'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/form-vertical',
  //   name: 'form-vertical',
  //   component: () => import('../views/pages/form/formvertical'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/goal-tracking',
  //   name: 'goal-tracking',
  //   component: () => import('../views/pages/goals/goaltracking'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/goal-type',
  //   name: 'goal-type',
  //   component: () => import('../views/pages/goals/goaltype'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/holidays',
  //   name: 'holidays',
  //   component: () => import('../views/pages/employees/holidays'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/inbox',
  //   name: 'inbox',
  //   component: () => import('../views/pages/app/inbox'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/incoming-call',
  //   name: 'incoming-call',
  //   component: () => import('../views/pages/app/call/incomingcall'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/interviewing',
  //   name: 'interviewing',
  //   component: () => import('../views/pages/jobs/interviewing'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/interview-questions',
  //   name: 'interview-questions',
  //   component: () => import('../views/pages/jobs/interviewquestions'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/invoice-reports',
  //   name: 'invoice-reports',
  //   component: () => import('../views/pages/reports/invoicereport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/invoices',
  //   name: 'invoices',
  //   component: () => import('../views/pages/sales/invoices/maininvoice'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/invoice-settings',
  //   name: 'invoice-settings',
  //   component: () => import('../views/pages/settings/invoicesettings'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/invoice-view',
  //   name: 'invoice-view',
  //   component: () => import('../views/pages/sales/invoices/invoiceview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/job-applicants',
  //   name: 'job-applicants',
  //   component: () => import('../views/pages/jobs/jobapplicants'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/job-aptitude',
  //   name: 'job-aptitude',
  //   component: () => import('../views/pages/jobs/jobaptitute'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/job-details',
  //   name: 'job-details',
  //   component: () => import('../views/pages/jobs/jobdetails'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/job-list',
  //   name: 'job-list',
  //   component: () => import('../views/pages/jobs/joblist'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/jobs',
  //   name: 'jobs',
  //   component: () => import('../views/pages/jobs/mainjobs'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/jobs-dashboard',
  //   name: 'jobs-dashboard',
  //   component: () => import('../views/pages/jobs/jobsdashboard'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/job-view',
  //   name: 'job-view',
  //   component: () => import('../views/pages/jobs/jobview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/knowledgebase',
  //   name: 'knowledgebase',
  //   component: () => import('../views/pages/knowledgebase'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/knowledgebase-view',
  //   name: 'knowledgebase-view',
  //   component: () => import('../views/pages/knowledgebase/view'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/leads',
  //   name: 'leads',
  //   component: () => import('../views/pages/leads'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/leave-reports',
  //   name: 'leave-reports',
  //   component: () => import('../views/pages/reports/leavereport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/leaves',
  //   name: 'leaves',
  //   component: () => import('../views/pages/employees/leave'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/leaves-employee',
  //   name: 'leaves-employee',
  //   component: () => import('../views/pages/employees/leaveemployee'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/leave-settings',
  //   name: 'leave-settings',
  //   component: () => import('../views/pages/employees/leavesettings'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/leave-type',
  //   name: 'leave-type',
  //   component: () => import('../views/pages/settings/leavetype'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/localization',
  //   name: 'localization',
  //   component: () => import('../views/pages/settings/localization'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/lock-screen',
  //   name: 'lock-screen',
  //   component: () => import('../views/pages/application/lockscreen'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/application/login'),
    meta: {
      middleware: auth,
    },
  },
  // {
  //   path: '/mail-view',
  //   name: 'mail-view',
  //   component: () => import('../views/pages/app/email/mailview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/manage-resumes',
  //   name: 'manage-resumes',
  //   component: () => import('../views/pages/jobs/manageresume'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/notifications-settings',
  //   name: 'notifications-settings',
  //   component: () => import('../views/pages/settings/notification'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/offer_approvals',
  //   name: 'offer_approvals',
  //   component: () => import('../views/pages/jobs/offerapprove'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/offered-jobs',
  //   name: 'offered-jobs',
  //   component: () => import('../views/pages/jobs/offeredjob'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/otp',
  //   name: 'otp',
  //   component: () => import('../views/pages/application/otp'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/outgoing-call',
  //   name: 'outgoing-call',
  //   component: () => import('../views/pages/app/call/outgoingcall'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/overtime',
  //   name: 'overtime',
  //   component: () => import('../views/pages/employees/overtime'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/payments',
  //   name: 'payments',
  //   component: () => import('../views/pages/sales/payments'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/payments-reports',
  //   name: 'payments-reports',
  //   component: () => import('../views/pages/reports/paymentreport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/payroll-items',
  //   name: 'payroll-items',
  //   component: () => import('../views/pages/payroll/payrollitems'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/payslip-reports',
  //   name: 'payslip-reports',
  //   component: () => import('../views/pages/reports/payslipreport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/performance',
  //   name: 'performance',
  //   component: () => import('../views/pages/performance'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/performance-appraisal',
  //   name: 'performance-appraisal',
  //   component: () => import('../views/pages/performance/performanceappraisal'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/performance-indicator',
  //   name: 'performance-indicator',
  //   component: () => import('../views/pages/performance/performanceindicator'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/performance-setting',
  //   name: 'performance-setting',
  //   component: () => import('../views/pages/settings/performancesetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/policies',
  //   name: 'policies',
  //   component: () => import('../views/pages/policies'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/privacy-policy',
  //   name: 'privacy-policy',
  //   component: () => import('../views/pages/mainpage/privacypolicy'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/profile',
  //   name: 'profile',
  //   component: () => import('../views/pages/profile'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/project-list',
  //   name: 'project-list',
  //   component: () => import('../views/pages/project/projectlist'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/project-reports',
  //   name: 'project-reports',
  //   component: () => import('../views/pages/reports/projectreport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/projects',
  //   name: 'projects',
  //   component: () => import('../views/pages/projects/mainproject'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/project-view',
  //   name: 'project-view',
  //   component: () => import('../views/pages/projects/projectview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/promotion',
  //   name: 'promotion',
  //   component: () => import('../views/pages/promotion'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/provident-fund',
  //   name: 'provident-fund',
  //   component: () => import('../views/pages/sales/providentfund'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/voice-call',
  //   name: 'voice-call',
  //   component: () => import('../views/pages/app/call/voicecall'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/visited-jobs',
  //   name: 'visited-jobs',
  //   component: () => import('../views/pages/jobs/visitedjobs'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/video-call',
  //   name: 'video-call',
  //   component: () => import('../views/pages/app/call/videocall'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: () => import('../views/pages/users'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/user-reports',
  //   name: 'user-reports',
  //   component: () => import('../views/pages/reports/userreport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/user-dashboard',
  //   name: 'user-dashboard',
  //   component: () => import('../views/pages/jobs/userdashboard'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/user-all-jobs',
  //   name: 'user-all-jobs',
  //   component: () => import('../views/pages/jobs/useralljobs'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/training-type',
  //   name: 'training-type',
  //   component: () => import('../views/pages/training/trainingtype'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/training',
  //   name: 'training',
  //   component: () => import('../views/pages/training/maintraining'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/trainers',
  //   name: 'trainers',
  //   component: () => import('../views/pages/training/trainers'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/toxbox-setting',
  //   name: 'toxbox-setting',
  //   component: () => import('../views/pages/settings/toxboxsetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/timesheet',
  //   name: 'timesheet',
  //   component: () => import('../views/pages/employees/timesheet'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/ticket-view',
  //   name: 'ticket-view',
  //   component: () => import('../views/pages/tickets/ticketview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/tickets',
  //   name: 'tickets',
  //   component: () => import('../views/pages/tickets/mainticket'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/theme-settings',
  //   name: 'theme-settings',
  //   component: () => import('../views/pages/settings/themesetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/terms',
  //   name: 'terms',
  //   component: () => import('../views/pages/mainpage/term'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/termination',
  //   name: 'termination',
  //   component: () => import('../views/pages/mainpage/termination'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/taxes',
  //   name: 'taxes',
  //   component: () => import('../views/pages/sales/taxes'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/tasks',
  //   name: 'tasks',
  //   component: () => import('../views/pages/tasks'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/task-reports',
  //   name: 'task-reports',
  //   component: () => import('../views/pages/reports/taskreport'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/task-board',
  //   name: 'task-board',
  //   component: () => import('../views/pages/projects/taskboard'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/tables-basic',
  //   name: 'tables-basic',
  //   component: () => import('../views/pages/table/tablebasic'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/subscriptions-company',
  //   name: 'subscriptions-company',
  //   component: () => import('../views/pages/subscription/subscriptioncompany'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/subscriptions',
  //   name: 'subscriptions',
  //   component: () => import('../views/pages/subscription/subscriptions'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/subscribed-companies',
  //   name: 'subscribed-companies',
  //   component: () => import('../views/pages/subscription/subscribedcompanies'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/sub-category',
  //   name: 'sub-category',
  //   component: () => import('../views/pages/accounting/subcategory'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/shortlist-candidates',
  //   name: 'shortlist-candidates',
  //   component: () => import('../views/pages/jobs/shortlistcandidate'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/questions',
  //   name: 'questions',
  //   component: () => import('../views/pages/jobs/question'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/pages/application/register'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/resignation',
  //   name: 'resignation',
  //   component: () => import('../views/pages/resignation'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/roles-permissions',
  //   name: 'roles-permissions',
  //   component: () => import('../views/pages/settings/rolepermission'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/salary',
  //   name: 'salary',
  //   component: () => import('../views/pages/payroll/salary'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/salary-settings',
  //   name: 'salary-settings',
  //   component: () => import('../views/pages/settings/salarysetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/salary-view',
  //   name: 'salary-view',
  //   component: () => import('../views/pages/payroll/salaryview'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/saved-jobs',
  //   name: 'saved-jobs',
  //   component: () => import('../views/pages/jobs/savedjob'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/schedule-timing',
  //   name: 'schedule-timing',
  //   component: () => import('../views/pages/jobs/scheduletiming'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/settings',
  //   name: 'settings',
  //   component: () => import('../views/pages/settings/mainsetting'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/shift-list',
  //   name: 'shift-list',
  //   component: () => import('../views/pages/employees/shift'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/shift-scheduling',
  //   name: 'shift-scheduling',
  //   component: () => import('../views/pages/employees/scheduling'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  // {
  //   path: '/search',
  //   name: 'search',
  //   component: () => import('../views/pages/mainpage/search'),
  //   meta: {
  //     middleware: auth,
  //   },
  // },
  {
    path: '/orders',
    name: 'orders-index',
    component: () => import('../views/pages/orders/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/add',
    name: 'order-add',
    component: () => import('../views/pages/orders/add/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/view/:id',
    name: 'order-view',
    component: () => import('../views/pages/orders/view/_id'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/detail/:id',
    name: 'order-detail',
    component: () => import('../views/pages/orders/detail/_id'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/print',
    name: 'order-print',
    component: () => import('../views/pages/orders/print'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/awb',
    name: 'order-awb',
    component: () => import('../views/pages/orders/awb'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/delivery-note',
    name: 'delivery-note',
    component: () => import('../views/pages/orders/DeliveryNote'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/document',
    name: 'order-document',
    component: () => import('../views/pages/orders/document'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/shipping-label',
    name: 'shipping-label',
    component: () => import('../views/pages/orders/shipping-label'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/orders/upload',
    name: 'order-upload',
    component: () => import('../views/pages/orders/upload'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/ship-order',
    name: 'ship-order',
    component: () => import('../views/pages/shiporder/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/ship-order/loop',
    name: 'ship-loop',
    component: () => import('../views/pages/shiporder/loop'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/products',
    name: 'product-index',
    component: () => import('../views/pages/products/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/products/create',
    name: 'product-create',
    component: () => import('../views/pages/products/create'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/products/create_option',
    name: 'product-option',
    component: () => import('../views/pages/products/option'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/products/variants',
    name: 'variants-index',
    component: () => import('../views/pages/products/variants/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/products/variants/create',
    name: 'variants-create',
    component: () => import('../views/pages/products/variants/create'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/products/variants-generator',
    name: 'variants-create',
    component: () => import('../views/pages/products/VariantsGenerator'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/warehouse',
    name: 'warehouse-index',
    component: () => import('../views/pages/warehouse/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/warehouse/create',
    name: 'warehouse-create',
    component: () => import('../views/pages/warehouse/create'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/warehouse/view/:id',
    name: 'warehouse-view',
    component: () => import('../views/pages/warehouse/view/_id'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/warehouse/stock-movement',
    name: 'warehouse-stockmovement',
    component: () => import('../views/pages/warehouse/stockmovement'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventory',
    name: 'inventory-index',
    component: () => import('../views/pages/inventory/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventory/stock-in',
    name: 'inventory-stockin',
    component: () => import('../views/pages/inventory/stockin'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventory/manual-stock-in',
    name: 'inventory-manualstock',
    component: () => import('../views/pages/inventory/import'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventory/movement',
    name: 'inventory-movement',
    component: () => import('../views/pages/inventory/movement'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/inventory/stock-out',
    name: 'inventory-stockout',
    component: () => import('../views/pages/inventory/stockout'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/goods-receipt',
    name: 'goods-receipt',
    component: () => import('../views/pages/goodsreceipt/index'),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/goods-receipt/create',
    name: 'goods-receipt-create',
    component: () => import('../views/pages/goodsreceipt/create'),
    meta: {
      middleware: auth,
    },
  },
];
export const router = createRouter({
  history: createWebHistory('/'),
  linkActiveClass: 'active',
  routes,
});
