import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/line-awesome.min.css';
import './assets/css/vue3-simple-typeahead.css';
import 'vue-next-select/dist/index.css';
import 'vue-select/dist/vue-select.css';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue3-toastify/dist/index.css';
/*********Header component**********/
import MainHeader from './views/layouts/mainheader.vue';
import Sidebar from './views/pages/sidebar.vue';
import HeaderLogo from './components/header-logo.vue';
import Toggle from './components/toggle.vue';
import Search from './components/search.vue';
import Flag from './components/flag.vue';
import Notifications from './components/notifications.vue';
import MessageNotifications from './components/message-notifications.vue';
import HeaderEnd from './components/header-end.vue';
import Spinner from './components/Spinner.vue';
/*********dashboard component******/
import PageHeader from './views/pages/dashboard/admin/pageheader.vue';
import Widget from './views/pages/dashboard/admin/widget.vue';
import Chart from './views/pages/dashboard/admin/chart.vue';
import Employee from './views/pages/dashboard/admin/employee.vue';
import Statistics from './views/pages/dashboard/admin/statistics.vue';
import Invoices from './views/pages/dashboard/admin/invoices.vue';
import Clients from './views/pages/dashboard/admin/clients.vue';
import Welcomeheader from './views/pages/dashboard/employee/welcomeheader.vue';
import Projects from './views/pages/dashboard/employee/projects.vue';
import Today from './views/pages/dashboard/employee/today.vue';

/*******Plugin import***********/
import VueSelect from 'vue3-select2-component';
import VSelect from 'vue-select';
import VueNextSelect from 'vue-next-select';
import DatePicker from 'vue3-datepicker';
import Notification from '@kyvg/vue3-notification';
import VueBasicAlert from 'vue-basic-alert';
import VueExcelXlsx from 'vue-excel-xlsx';
import VueDatepicker from '@vuepic/vue-datepicker';
import Vue3Toasity from 'vue3-toastify';
import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf';
import setupInterceptors from '../src/plugins/Axios/intercept';
import 'moment';

import jquery from 'jquery';
window.$ = jquery;
import './assets/css/style.css';
import './assets/js/bootstrap.bundle.min.js';
import './assets/js/Chart.min.js';
import './assets/css/bootstrap4.min.css';
import './assets/css/dataTables.bootstrap4.min.css';
import './assets/css/jquery.dataTables.min.js';
import './assets/css/dataTables.bootstrap4.min.js';

const app = createApp(App);
app.component('sidebar', Sidebar);

/*********Header component**********/
app.component('main-header', MainHeader);
app.component('header-logo', HeaderLogo);
app.component('toggle', Toggle);
app.component('search', Search);
app.component('flag', Flag);
app.component('notifications', Notifications);
app.component('messagenotifications', MessageNotifications);
app.component('headerend', HeaderEnd);
app.component('spinner', Spinner);

/*********admin dashboard component******/
app.component('pageheader', PageHeader);
app.component('widget', Widget);
app.component('chart', Chart);
app.component('employee', Employee);
app.component('statistics', Statistics);
app.component('invoices', Invoices);
app.component('clients', Clients);

app.component('vue-select', VueSelect);
app.component('vue3-select', VSelect);
app.component('v-select', VueNextSelect);
app.component('datepicker', DatePicker);
app.component('vue-datepicker', VueDatepicker);
app.use(VueBasicAlert);
app.use(VueExcelXlsx);
app.use(Notification);
app.use(Vue3Toasity);
app.use(localStorage);
app.use(Vue3SimpleHtml2pdf);
let memberInfo = JSON.parse(localStorage.getItem('memberInfo'));
app.provide('memberInfo', memberInfo);
setupInterceptors(store);
app.use(router).use(store).mount('#app');
