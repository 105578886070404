<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card-group m-b-30">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">สินค้า</span>
              </div>
              <div>
                <span class="text-success">0</span>
              </div>
            </div>
            <h3 class="mb-3">0</h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 70%"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">จำนวนสินค้าที่ขายได้</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">กำไร</span>
              </div>
              <div>
                <span class="text-success">0</span>
              </div>
            </div>
            <h3 class="mb-3">0</h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 70%"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">กำไรเดือนที่แล้ว</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">ต้นทุนสินค้า</span>
              </div>
              <div>
                <span class="text-success">0</span>
              </div>
            </div>
            <h3 class="mb-3">0</h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 70%"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">ต้นทุนสินค้าเดือนที่แล้ว</p>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-3">
              <div>
                <span class="d-block">ค่าใช้จ่าย</span>
              </div>
              <div>
                <span class="text-success">0</span>
              </div>
            </div>
            <h3 class="mb-3">0</h3>
            <div class="progress mb-2" style="height: 5px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 70%"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="mb-0">ค่าใช้จ่ายเดือนที่แล้ว</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import adminemployee from '../../../../assets/json/adminemployee.json';
import Vue from 'vue';
export default {
  data() {
    return {
      adminemployee: adminemployee,
    };
  },
  components: {},
};
</script>
