import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
    customer: [],
    customers: [],
    stockin: [],
    stockout: [],
    item: [],
    return: [],
    list: [],
    inventory_approve: [],
    inventory_reject: [],
    inventory_cancel: [],
  },
  getters: {
    data: (state) => state.data,
    datas: (state) => state.customers,
    dataInfo: (state) => state.customer,
    stockInfo: (state) => state.stockin,
    stockOutInfo: (state) => state.stockout,
    itemInfo: (state) => state.item,
    returnInfo: (state) => state.return,
    listInfo: (state) => state.list,
    approveInfo: (state) => state.inventory_approve,
    rejectInfo: (state) => state.inventory_reject,
    cancelInfo: (state) => state.inventory_cancel,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_DATAS(state, data) {
      state.datas = data;
    },
    SET_STOCKIN(state, data) {
      state.stockin = data;
    },
    SET_STOCKOUT(state, data) {
      state.stockout = data;
    },
    SET_ITEM(state, data) {
      state.item = data;
    },
    SET_DATA_RETURN(state, data) {
      state.return = data;
    },
    SET_DATA_LIST(state, data) {
      state.list = data;
    },
    SET_DATA_APPROVE(state, data) {
      state.inventory_approve = data;
    },
    SET_DATA_REJECT(state, data) {
      state.inventory_reject = data;
    },
    SET_DATA_CANCEL(state, data) {
      state.inventory_cancel = data;
    },
  },
  actions: {
    List: async ({ commit }, payload) => {
      let res = await http.get(`/inventory`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA_LIST', result);
      return result;
    },
    fetchData: async ({ commit }, payload) => {
      const { shop_id, inventory_id } = payload;
      let res = await http.get(`/inventory/${shop_id}/${inventory_id}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    fetchDatas: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.get(`/inventory/${shop_id}`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATAS', result);
      return result;
    },
    create: async ({ commit }, payload) => {
      let res = await http.post(`/inventory`, payload);
      let result = res.data != undefined ? res.data : [];
      return result;
    },
    stockIn: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.get(`/inventory/${shop_id}/stock-in`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_STOCKIN', result);
      return result;
    },
    stockOut: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.get(`/inventory/${shop_id}/stock-out`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_STOCKOUT', result);
      return result;
    },
    approve: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.post(`/inventory/${shop_id}/approve`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_ITEM', result);
      return result;
    },
    return: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.post(`/inventory/${shop_id}/return`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA_RETURN', result);
      return result;
    },
    onApprove: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.post(
        `/inventory/${shop_id}/approve-goods-reeceipt`,
        payload
      );
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA_APPROVE', result);
      return result;
    },
    onReject: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.post(
        `/inventory/${shop_id}/reject-goods-reeceipt`,
        payload
      );
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA_REJECT', result);
      return result;
    },
    onCancel: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.post(
        `/inventory/${shop_id}/cancel-goods-reeceipt`,
        payload
      );
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA_CANCEL', result);
      return result;
    },
  },
};
