<template>
  <div>
    <header>
      <!-- Header -->
      <div class="header">
        <header-logo />

        <toggle />

        <!-- Header Title -->
        <div class="page-title-box"></div>
        <!-- /Header Title -->

        <a id="mobile_btn" class="mobile_btn" href="#sidebar"
          ><i class="fa fa-bars"></i
        ></a>

        <!-- Header Menu -->
        <ul class="nav user-menu">
          <!-- <flag /> -->

          <notifications />

          <messagenotifications />

          <li class="nav-item dropdown has-arrow main-drop">
            <a
              href="#"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span class="user-img me-1"
                ><img :src="`${user.avatar}`" alt="" />
                <span class="status online"></span
              ></span>
              <span class="adminclass">{{ user.fullName }}</span>
            </a>
            <div class="dropdown-menu">
              <router-link class="dropdown-item" to="/settings/profile"
                >My Profile</router-link
              >
              <router-link class="dropdown-item" to="/setting"
                >Settings</router-link
              >
              <button type="button" class="dropdown-item" @click="onLogout()">
                Logout
              </button>
            </div>
          </li>
        </ul>
        <div class="dropdown mobile-user-menu">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="fa fa-ellipsis-v"></i
          ></a>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link class="dropdown-item" to="/settings/profile"
              >My Profile</router-link
            >
            <router-link class="dropdown-item" to="/setting"
              >Settings</router-link
            >
            <button type="button" class="dropdown-item" @click="onLogout()">
              Logout
            </button>
          </div>
        </div>
      </div>
      <!-- /Header -->
    </header>
  </div>
</template>
<script>
import {
  reactive,
  defineComponent,
  defineAsyncComponent,
  onMounted,
  onDeactivated,
  computed,
  ref,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const user = ref({});
    onMounted(async () => {
      // Variables declarations
      fetchUser();
      var $wrapper = $('.main-wrapper');
      $('body').append('<div class="sidebar-overlay"></div>');
      $(document).on('click', '#mobile_btn', function () {
        $wrapper.toggleClass('slide-nav');
        $('.sidebar-overlay').toggleClass('opened');
        $('html').addClass('menu-opened');
        $('#task_window').removeClass('opened');
        return false;
      });
      $('.sidebar-overlay').on('click', function () {
        $('html').removeClass('menu-opened');
        $(this).removeClass('opened');
        $wrapper.removeClass('slide-nav');
        $('.sidebar-overlay').removeClass('opened');
        $('#task_window').removeClass('opened');
      });
      $(document).on(
        'click',
        '.top-nav-search .responsive-search',
        function () {
          $('.top-nav-search').toggleClass('active');
        }
      );
      $('.sidebar-menu ul li:not(.submenu) a').click(function () {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
      });
      $(document).on('click', '#toggle_btn', function () {
        if ($('body').hasClass('mini-sidebar')) {
          $('body').removeClass('mini-sidebar');
          $('.subdrop + ul').slideDown();
        } else {
          $('body').addClass('mini-sidebar');
          $('.subdrop + ul').slideUp();
        }
        return false;
      });
      $(document).on('mouseover', function (e) {
        e.stopPropagation();
        if (
          $('body').hasClass('mini-sidebar') &&
          $('#toggle_btn').is(':visible')
        ) {
          var targ = $(e.target).closest('.sidebar').length;
          if (targ) {
            $('body').addClass('expand-menu');
            $('.subdrop + ul').slideDown();
          } else {
            $('body').removeClass('expand-menu');
            $('.subdrop + ul').slideUp();
          }
          return false;
        }
      });
    });
    const fetchUser = async () => {
      // await store.dispatch(`users/fetchData`, payload);
      // let user = store.getters['users/memberInfo'];
      // console.log('user', user);
      let User = localStorage.getItem('memberInfo');
      let Token = localStorage.getItem('access_token');
      if (!User && !Token) {
        await localStorage.clear();
        await router.push('/login');
      }
      let userInfo = JSON.parse(User);
      user.value = userInfo;
    };
    const onLogout = async () => {
      await localStorage.removeItem('memberInfo');
      await localStorage.removeItem('access_token');
      await router.push('/login');
    };
    return { onLogout, user };
  },
});
</script>
