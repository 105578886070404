import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    chart: [],
    statistics: [],
  },
  getters: {
    chartInfo: (state) => state.chart,
    statisticsInfo: (state) => state.statistics,
  },
  mutations: {
    SET_DATA(state, data) {
      state.chart = data;
    },
    SET_STATISTICS(state, data) {
      state.statistics = data;
    },
  },
  actions: {
    fetchData: async ({ commit }, payload) => {
      let res = await http.get(`/report`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    fetchStatistics: async ({ commit }, payload) => {
      let res = await http.get(`/report/statistics`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_STATISTICS', result);
      return result;
    },
  },
};
