import { createStore } from 'vuex';
import http from './../plugins/Axios/axios';
import address from './address';
import carrier from './carrier';
import customer from './customer';
import inventory from './inventory';
import order from './order';
import order_status from './order_status';
import product from './product';
import salechannel from './salechannel';
import payment from './payment';
import product_reserve from './product_reserve';
import product_stock from './product_stock';
import product_attributes from './product_attributes';
import product_variants from './product_variants';
import report from './report';
import setting_carrier from './setting_carrier';
import shipping from './shipping';
import ship_order from './ship_order';
import transaction_order from './transaction_order';
import upload from './upload';
import member from './member';
import warehouse from './warehouse';
export default createStore({
  state: {
    users: [],
    token: null,
    userInfo: [],
  },
  getters: {
    getUsers: (state) => state.users,
    getToken: (state) => state.token,
    userInfo: (state) => state.userInfo,
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_TOKEN(state, token) {
      const access_token = $cookies.get('access_token');
      const getAccessToken = token != null ? token : access_token;
      state.token = getAccessToken;
      localStorage.setItem('access_token', token);
    },
    SET_USER(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem('memberInfo', userInfo);
    },
  },
  actions: {
    fetchUsers: ({ commit }, id) => {
      http
        .get(`/ad/accounts/${id}`)
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_USERS', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_USERS', []);
        });
    },
    getAccessToken: ({ commit }) => {
      let token = $cookies.get('access_token');
      commit('SET_TOKEN', token);
      return token;
    },
  },
  modules: {
    address,
    carrier,
    customer,
    inventory,
    order,
    order_status,
    payment,
    product,
    product_attributes,
    product_variants,
    salechannel,
    shipping,
    product_reserve,
    product_stock,
    upload,
    transaction_order,
    ship_order,
    setting_carrier,
    report,
    member,
    warehouse,
  },
});
