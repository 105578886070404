import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
    customer: [],
    customers: [],
  },
  getters: {
    data: (state) => state.data,
    dataInfo: (state) => state.customer,
    datas: (state) => state.customers,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_CUSTOMER(state, data) {
      state.customer = data;
    },
    SET_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
  },
  actions: {
    fetchData: async ({ commit }, payload) => {
      const { shop_id, customer_id } = payload;
      let res = await http.get(`/customer/${shop_id}/${customer_id}`, {
        params: payload,
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    fetchDatas: async ({ commit }, payload) => {
      const { shop_id } = payload;
      let res = await http.get(`/customer/${shop_id}`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_CUSTOMERS', result);
      return result;
    },
    create: async ({ commit }, payload) => {
      let res = await http.post(`/customer`, payload);
      let result = res.data != undefined ? res.data : [];
      commit('SET_CUSTOMER', result);
      return result;
    },
  },
};
