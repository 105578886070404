class TokenService {
  getLocalRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getLocalAccessToken() {
    return localStorage.getItem('access_token');
  }

  updateLocalAccessToken(token) {
    localStorage.setItem('access_token', token);
  }

  getUser() {
    return localStorage.getItem('access_token');
  }

  setUser(user) {
    localStorage.setItem('access_token', user);
  }

  removeUser() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('memberInfo');
  }
}

export default new TokenService();
