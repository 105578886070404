import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    data: [],
    addr: {},
    address: [],
  },
  getters: {
    data: (state) => state.data,
    addr: (state) => state.addr,
    address: (state) => state.address,
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_ADDRESS(state, address) {
      state.address = address;
    },
    SET_ADDR(state, data) {
      state.addr = data;
    },
  },
  actions: {
    find: async ({ commit }, payload) => {
      let res = await http.get(`/address`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_DATA', result);
      return result;
    },
    findAll: async ({ commit }, payload) => {
      let res = await http.get(`/address`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_ADDRESS', result);
      return result;
    },
    search: async ({ commit }, payload) => {
      let res = await http.get(`/address/search`, { params: payload });
      let result = res.data != undefined ? res.data : [];
      commit('SET_ADDR', result);
      return result;
    },
  },
};
