import http from './../plugins/Axios/axios';
export default {
  namespaced: true,
  state: {
    payments: [],
    account: [],
    provider: [],
    image: [],
  },
  getters: {
    getPayments: (state) => state.payments,
    getAccount: (state) => state.account,
    getProvider: (state) => state.provider,
    paymentSlip: (state) => state.image,
  },
  mutations: {
    SET_PAYMENTS(state, payments) {
      state.payments = payments;
    },
    SET_PROVIDER(state, provider) {
      state.provider = provider;
    },
    SET_ACCOUNT(state, account) {
      state.account = account;
    },
    SET_IMAGE(state, image) {
      state.image = image;
    },
  },
  actions: {
    fetchData: ({ commit }) => {
      http
        .get(`/payments`)
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_PAYMENTS', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_PAYMENTS', []);
        });
    },
    provider: ({ commit }, payload) => {
      http
        .get(`/payment_provider`)
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_PROVIDER', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_PROVIDER', []);
        });
    },
    account: ({ commit }, shop_id) => {
      const shopId = shop_id != undefined ? shop_id : 0;
      const payload = {
        shop_id: shopId,
      };
      http
        .get(`/payment_account/${shopId}`, { params: payload })
        .then((res) => {
          let result = res.data != undefined ? res.data : [];
          commit('SET_ACCOUNT', result);
        })
        .catch((err) => {
          console.log(err);
          commit('SET_ACCOUNT', []);
        });
    },
    confirm: async ({ commit }, payload) => {
      let res = await http.post(`/payments/confirm_transfer`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      let result = res.data != undefined ? res.data : [];
      commit('SET_IMAGE', result);
      return result;
    },
  },
};
